package com.essntl.features.proposal.data.supabase.fullproposal.fullservice

import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullCategoryModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FullCategoryDto(
    val name: String,
    @SerialName("icon_url")
    val iconUrl: String,
) {
    fun toModel() =
        FullCategoryModel(
            name = name,
            iconUrl = iconUrl,
        )
}
