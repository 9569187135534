package client.di

import com.essntl.core.network.networkModule
import com.essntl.core.supabase.supabaseModule
import core.datastore.di.datastoreModule
import com.essntl.core.utils.utils.loadingmanager.loadingManagerModule
import com.essntl.core.utils.utils.windowmanager.windowManagerModule
import com.essntl.features.area.data.di.AreaModule
import com.essntl.features.category.data.di.CategoryModule
import com.essntl.features.client.data.di.ClientModule
import com.essntl.features.file.data.di.FileModule
import com.essntl.features.itinerary.data.di.ItineraryModule
import com.essntl.features.notification.data.di.NotificationModule
import com.essntl.features.paymentlink.data.di.PaymentLinkModule
import com.essntl.features.proposal.data.di.ProposalModule
import com.essntl.features.service.data.di.ServiceModule
import com.essntl.features.tag.data.di.TagModule
import com.essntl.features.travelagent.data.di.TravelAgentModule
import com.essntl.toastmanager.toastManagerModule
import core.dateformat.di.DateFormatUseCaseModule
import org.koin.ksp.generated.module

val appModule
    get() = listOf(
        // Core modules
        networkModule,
        supabaseModule,
        toastManagerModule,
        loadingManagerModule,
        windowManagerModule,
        datastoreModule,

        // Feature modules
        ProposalModule().module,
        FileModule().module,
        CategoryModule().module,
        TagModule().module,
        AreaModule().module,
        ServiceModule().module,
        ClientModule().module,
        NotificationModule().module,
        TravelAgentModule().module,
        ItineraryModule().module,
        PaymentLinkModule().module,
        DateFormatUseCaseModule().module,
    )