package client.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import client.Branding
import client.components.widgets.EsSmallOutlineIconButton
import client.pages.ServiceUiState
import client.ui.EsIcons
import client.ui.iconSrc
import client.ui.imageSrc
import com.essntl.core.utils.datetime.formatToFullString
import com.essntl.features.proposal.domain.model.fullproposal.FullProposalItineraryModel
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullFileModel
import com.varabyte.kobweb.compose.css.TextTransform
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.rotateZ
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textTransform
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import core.config.buildFlavor
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.px

@Composable
fun ItineraryDayInfos(
    fullProposal: FullProposalItineraryModel,
    day: LocalDate,
    dayNumber: Int,
    isItinerary: Boolean,
    allDayServices: List<ServiceUiState>,
    onOpenImageFullScreen: (imageList: List<String>, imageIndex: Int) -> Unit,
    onDownloadFile: (FullFileModel) -> Unit,
    modifier: Modifier = Modifier,
) {
    val breakpoint = rememberBreakpoint()

    var isExpanded by remember {
        mutableStateOf(true)
    }

    var selectedProposalServiceId by remember {
        mutableStateOf("")
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(
                topBottom =
                    if (breakpoint < Breakpoint.MD)
                        6.px
                    else
                        10.px,
                leftRight =
                    if (breakpoint < Breakpoint.MD)
                        10.px
                    else
                        20.px,
            )
            .id("day-$dayNumber"),
    ) {
        Column(
            modifier = modifier
                .fillMaxWidth()
                .borderRadius(28.px)
                .backgroundColor(Branding.backgroundSecondary),
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .borderRadius(
                        topLeft = 28.px,
                        topRight = 28.px,
                    )
                    .backgroundColor(Branding.backgroundPrimary)
                    .padding(
                        topBottom = 28.px,
                        leftRight = if (breakpoint < Breakpoint.MD)
                            20.px
                        else
                            40.px,
                    ),
            ) {
                if (!buildFlavor.isDemo &&
                    !fullProposal.hideBranding
                ) {
                    Image(
                        src = imageSrc(EsIcons.BeLogoKeyNude),
                        modifier = Modifier
                            .size(60.px)
                            .margin(right = 10.px)
                            .displayIfAtLeast(Breakpoint.MD)
                            .onClick { },
                    )
                }

                SpanText(
                    text = "Day $dayNumber",
                    modifier = Modifier
                        .fontSize(
                            if (breakpoint < Breakpoint.MD)
                                16.px
                            else
                                28.px,
                        )
                        .lineHeight(
                            if (breakpoint < Breakpoint.MD)
                                20.px
                            else
                                32.px,
                        )
                        .fontWeight(600)
                        .color(Colors.White)
                        .textTransform(TextTransform.Capitalize)
                        .margin(right = 10.px),
                )

                EsSmallOutlineIconButton(
                    onClick = {
                        isExpanded = !isExpanded
                    },
                    iconRes = iconSrc(EsIcons.ArrowUp),
                    modifier = Modifier
                        .rotateZ(if (isExpanded) 0.deg else 180.deg)
                        .margin(right = 10.px),
                )

                Spacer()

                SpanText(
                    text = day.formatToFullString(),
                    modifier = Modifier
                        .textTransform(TextTransform.Capitalize)
                        .fontFamily("PP Mori", "Manrope", "sans-serif")
                        .fontSize(
                            if (breakpoint < Breakpoint.MD)
                                16.px
                            else
                                28.px,
                        )
                        .lineHeight(
                            if (breakpoint < Breakpoint.MD)
                                20.px
                            else
                                32.px,
                        )
                        .fontWeight(600)
                        .color(Colors.White),
                )
            }

            ItineraryDayInfosExpanded(
                isItinerary = isItinerary,
                allDayServices = allDayServices,
                selectedProposalServiceId = selectedProposalServiceId,
                onOpenImageFullScreen = onOpenImageFullScreen,
                onDownloadFile = onDownloadFile,
                modifier = Modifier
                    .display(
                        if (isExpanded)
                            DisplayStyle.InlineBlock
                        else
                            DisplayStyle.None,
                    ),
            )

            ItineraryDayInfosCollapsed(
                isExpanded = isExpanded,
                allDayProposalService = allDayServices,
                onProposalServiceSelected = {
                    isExpanded = true
                    selectedProposalServiceId = it.id
                },
                modifier =
                    if (isExpanded)
                        Modifier.display(DisplayStyle.None)
                    else
                        Modifier,
            )
        }
    }
}