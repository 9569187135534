package com.essntl.features.proposal.data.supabase.fullproposal

import com.essntl.features.proposal.domain.model.fullproposal.FullAreaModel
import kotlinx.serialization.Serializable

@Serializable
data class FullAreaDto(
    val name: String,
    val country: String,
) {
    fun toModel() =
        FullAreaModel(
            name = name,
            country = country,
        )
}