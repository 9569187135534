package core.dateformat.ui

import androidx.compose.runtime.Composable
import core.dateformat.domain.DateFormatUseCase
import org.koin.compose.koinInject

@Composable
fun String.formatDate(
    separator: String = "/",
): String {
    val dateFormatUseCase = koinInject<DateFormatUseCase>()
    return dateFormatUseCase.formatDate(this, separator)
}