package client.pages.components.service

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import client.pages.ServiceUiState
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullCategoryModel
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullFileModel
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullServiceModel
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun DefaultService(
    isItinerary: Boolean,
    proposalService: ServiceUiState,
    service: FullServiceModel,
    category: FullCategoryModel?,
    allImage: List<String>,
    files: List<FullFileModel>,
    onDownloadFile: (FullFileModel) -> Unit,
    onOpenImageFullScreen: (imageList: List<String>, imageIndex: Int) -> Unit,
) {
    val nonNullImageList = remember(allImage) { allImage.filterNotNull() }
    val breakpoint = rememberBreakpoint()

    val showFullDetails = remember(breakpoint, nonNullImageList, proposalService.linkedId) {
        breakpoint < Breakpoint.MD || nonNullImageList.isEmpty() || proposalService.linkedId != null
    }

    Column(
        modifier = Modifier
            .fillMaxWidth(if (showFullDetails) 100.percent else 66.66f.percent)
            .margin(
                right = if (showFullDetails) 0.px else 20.px,
            ),
    ) {
        DefaultServiceDetails(
            isItinerary = isItinerary,
            proposalService = proposalService,
            service = service,
            category = category,
            files = files,
            onDownloadFile = onDownloadFile,
        )
    }

    val showImage = remember(breakpoint, nonNullImageList, proposalService.linkedId) {
        nonNullImageList.isNotEmpty() && proposalService.linkedId == null
    }

    if (showImage)
        Column(
            modifier = Modifier
                .fillMaxWidth(if (breakpoint < Breakpoint.MD) 100.percent else 33.33f.percent)
                .height(300.px)
                .borderRadius(16.px)
                .overflow(Overflow.Hidden),
        ) {
            if (nonNullImageList.size == 1) {
                val image = remember(nonNullImageList) { nonNullImageList.first() }

                DefaultServiceImage(
                    serviceImage = image,
                    onImageClicked = {
                        onOpenImageFullScreen(listOf(image), 0)
                    },
                    category = category,
                    modifier = Modifier
                        .fillMaxSize(),
                )
            } else {
                DefaultServicePager(
                    allImage = allImage,
                    onOpenImageFullScreen = onOpenImageFullScreen,
                    category = category,
                )
            }
        }
}