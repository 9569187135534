package client.pages

import com.essntl.core.utils.viewmodel.State
import com.essntl.features.proposal.domain.model.fullproposal.FullProposalItineraryModel

data class ProposalState(
    val getFullProposal: State<FullProposalItineraryModel> = State(),
) {
    fun itineraryServicesToUiStateList(fullProposal: FullProposalItineraryModel): List<ServiceUiState> {
        return fullProposal.services
            .map {
                ServiceUiState(
                    id = it.id,
                    service = it.service,
                    grouped = it.grouped,
                    additionalInfo = it.additionalInfo,
                    editedDescription = it.editedDescription,
                    startDate = it.startDate,
                    startTime = it.startTime,
                    endTime = it.endTime,
                    index = 0,
                    status = it.status,
                    price = it.price,
                    address = it.address,
                    contactName = it.contactName,
                    contactPhoneNumber = it.contactPhoneNumber,
                    dynamicFields = it.dynamicFields,
                    paid = it.paid,
                    embeddedMap = it.embeddedMap,
                    embeddedContent = it.embeddedContent,
                    files = it.attachments,
                    linkedId = it.linkedId,
                    hidePrices = fullProposal.hidePrices,
                )
            }
    }

    fun proposalServicesToUiStateList(fullProposal: FullProposalItineraryModel): List<ServiceUiState> {
        return fullProposal.services
            .map {
                ServiceUiState(
                    id = it.id,
                    service = it.service,
                    grouped = it.grouped,
                    additionalInfo = it.additionalInfo,
                    editedDescription = it.editedDescription,
                    startDate = it.startDate,
                    startTime = it.startTime,
                    endTime = it.endTime,
                    index = it.index,
                    status = it.status,
                    price = it.price,
                    address = it.address,
                    contactName = it.contactName,
                    contactPhoneNumber = it.contactPhoneNumber,
                    paid = it.paid,
                    embeddedMap = it.embeddedMap,
                    embeddedContent = it.embeddedContent,
                    files = it.attachments,
                    hidePrices = fullProposal.hidePrices,
                    dynamicFields = null,
                    linkedId = null,
                )
            }
    }
}