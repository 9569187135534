package client.pages.components.hotel

import androidx.compose.runtime.Composable
import client.pages.ServiceUiState
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth

@Composable
fun HotelSection(
    allProposalService: List<ServiceUiState>,
    onOpenImageFullScreen: (imageList: List<String>, imageIndex: Int) -> Unit,
    modifier: Modifier = Modifier,
) {
    Column(
        modifier = modifier
            .fillMaxWidth()
    ) {
//        HotelHeader()

        HotelList(
            allProposalService = allProposalService,
            onOpenImageFullScreen = onOpenImageFullScreen,
        )
    }
}