package com.essntl.features.proposal.data.supabase.fullproposal

import com.essntl.features.proposal.domain.model.fullproposal.FullClientModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FullClientDto(
    val salutation: String?,
    @SerialName("pretty_name")
    val prettyName: String,
) {
    fun toModel() =
        FullClientModel(
            salutation = salutation,
            prettyName = prettyName,
        )
}
