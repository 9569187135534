package com.essntl.features.proposal.data.supabase.fullproposal

import com.essntl.features.proposal.domain.model.fullproposal.FullTravelAgentModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FullTravelAgentDto(
    @SerialName("first_name")
    val firstName: String,
    @SerialName("last_name")
    val lastName: String?,
    @SerialName("logo_image_url")
    val logoImageUrl: String? = null,
    val agency: String?,
) {
    fun toModel() =
        FullTravelAgentModel(
            firstName = firstName,
            lastName = lastName,
            agency = agency,
            logoImageUrl = logoImageUrl,
        )
}
