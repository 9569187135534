package core.datastore.data

import com.russhwolf.settings.Settings
import org.koin.core.annotation.Single

@Single
class DatastoreManager(
    private val settings: Settings,
) {
    fun saveData(key: String, value: String) =
        settings.putString(key, value)

    fun getData(key: String): String? =
        settings.getStringOrNull(key)

    fun saveBooleanData(key: String, value: Boolean) =
        settings.putBoolean(key, value)

    fun getBooleanData(key: String): Boolean =
        settings.getBooleanOrNull(key) != false

    fun clearData(key: String) =
        settings.remove(key)

    fun clearAllData() = settings.clear()

    companion object {
        const val FIRST_TIME = "first_time"
        const val PROPOSALS = "proposals"
        const val DATE_FORMAT = "date_format"
    }
}