package client.termsandconditions

import androidx.compose.runtime.Composable
import client.components.widgets.EsSmallOutlineIconButton
import client.ui.EsIcons
import client.ui.iconSrc
import client.ui.imageSrc
import com.essntl.features.proposal.domain.model.fullproposal.FullProposalItineraryModel
import com.varabyte.kobweb.compose.css.TextTransform
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.px

@Composable
fun TermsAndConditionsHeader(
    fullProposal: FullProposalItineraryModel,
    isExpanded: Boolean,
    onToggleExpand: () -> Unit,
) {
    val breakpoint = rememberBreakpoint()

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .borderRadius(
                topLeft = 28.px,
                topRight = 28.px,
            )
            .backgroundColor(Colors.Black)
            .padding(
                topBottom = 28.px,
                leftRight = 40.px,
            ),
    ) {
        SpanText(
            text = "Terms And Conditions",
            modifier = Modifier
                .fontSize(
                    if (breakpoint < Breakpoint.MD)
                        20.px
                    else
                        28.px,
                )
                .lineHeight(
                    if (breakpoint < Breakpoint.MD)
                        24.px
                    else
                        32.px,
                )
                .fontWeight(600)
                .color(Colors.White)
                .textTransform(TextTransform.Capitalize)
                .margin(right = 10.px),
        )

        EsSmallOutlineIconButton(
            onClick = {
                onToggleExpand()
            },
            iconRes = iconSrc(EsIcons.ArrowUp),
            modifier = Modifier
                .rotateZ(if (isExpanded) 0.deg else 180.deg)
                .margin(right = 10.px),
        )

        if (!fullProposal.hideBranding) {
            Spacer()
            Image(
                src = imageSrc(EsIcons.BeLogoKeyNude),
                modifier = Modifier
                    .size(60.px)
                    .margin(right = 10.px),
            )
        }
    }
}