package com.essntl.features.proposal.domain.model.fullproposal

import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullServicesModel

data class FullProposalItineraryModel(
    val title: String,
    val headline: String?,
    val fromDate: String,
    val toDate: String,
    val areas: List<FullAreaModel>,
    val primaryClient: FullClientModel?,
    val travelAgent: FullTravelAgentModel?,
    val coverImageUrl: String?,
    val services: List<FullServicesModel>,
    val hidePrices: Boolean,
    val hideBranding: Boolean,
) {
    companion object {
        val empty =
            FullProposalItineraryModel(
                title = "",
                headline = null,
                fromDate = "",
                toDate = "",
                areas = emptyList(),
                primaryClient = null,
                travelAgent = null,
                coverImageUrl = null,
                services = emptyList(),
                hidePrices = false,
                hideBranding = false,
            )
    }
}
