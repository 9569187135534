package com.essntl.core.utils.datetime

import com.essntl.core.utils.utils.capitalizeFirstLetter
import kotlinx.datetime.*

/**
 * Calculates the difference in days between two dates.
 *
 * @param fromDate The starting date in the format "yyyy-mm-dd" or "mm/dd/yyyy".
 * @param toDate The ending date in the format "yyyy-mm-dd" or "mm/dd/yyyy".
 * @return The number of days between the two dates, or null if either fromDate or toDate is null
 *         or if the date strings do not match the expected format.
 */
fun dateDiffInDays(
    fromDate: String?,
    toDate: String?,
): Int? {
    if (toDate == null || fromDate == null) {
        return null
    }

    val toLocalDate = parseLocalDateOrNull(toDate) ?: return null
    val fromLocalDate = parseLocalDateOrNull(fromDate) ?: return null

    return fromLocalDate.daysUntil(toLocalDate)
}

/**
 * Compare the start time and end time.
 *
 * @param startTime The start time in the format "HH:mm:ss".
 * @param endTime The end time in the format "HH:mm:ss".
 *
 * @return null if either the start time or end time is null or not in the correct format.
 *         -1 if the end time is before the start time.
 *         0 if the end time is the same as the start time.
 *         1 if the end time is after the start time.
 */
fun timeDiff(
    startTime: String?,
    endTime: String?,
): Int? {
    if (startTime == null || endTime == null) {
        return null
    }

    val startLocalTime =
        try {
            startTime.toLocalTime()
        } catch (e: Exception) {
            return null
        }
    val endLocalTime =
        try {
            endTime.toLocalTime()
        } catch (e: Exception) {
            return null
        }

    return endLocalTime.compareTo(startLocalTime)
}

fun timeCheck(time: String): Boolean {
    return try {
        time.toLocalTime()
        true
    } catch (e: Exception) {
        false
    }
}

/**
 * Checks if a given date falls within a specified date range.
 *
 * @param date the date to be checked. Format must be "yyyy-mm-dd" or "mm/dd/yyyy".
 * @param fromDate the start date of the date range. Format must be "yyyy-mm-dd" or "mm/dd/yyyy".
 * @param toDate the end date of the date range. Format must be "yyyy-mm-dd" or "mm/dd/yyyy".
 * @return `true` if the given date falls within the specified range, `false` otherwise.
 *         Returns `null` if any of the input parameters are null or if the dates cannot be parsed.
 */
fun dateInRange(
    date: String?,
    fromDate: String?,
    toDate: String?,
): Boolean? {
    if (toDate == null || fromDate == null || date == null) {
        return null
    }

    val toLocalDate = parseLocalDateOrNull(toDate) ?: return null
    val fromLocalDate = parseLocalDateOrNull(fromDate) ?: return null
    val dateLocal = parseLocalDateOrNull(date) ?: return null

    return fromLocalDate.daysUntil(dateLocal) >= 0 && dateLocal.daysUntil(toLocalDate) >= 0
}

/**
 * Formats the given milliseconds to "mm.dd.yyyy".
 *
 * Example:
 * 1612137600000 will return "02.01.2021".
 *
 * @param millis The date in milliseconds to be formatted.
 * @return The formatted date string.
 * @throws IllegalArgumentException if the text cannot be parsed or the boundaries of [LocalDate] are exceeded.
 */
fun formatMillisToMMDDYYYY(
    millis: Long,
    separator: String = ".",
): String {
    var string = ""
    val localDateTime =
        Instant
            .fromEpochMilliseconds(millis)
            .toLocalDateTime(TimeZone.UTC)

    string += formatNumber(localDateTime.monthNumber) + separator

    string += formatNumber(localDateTime.dayOfMonth) + separator

    string += localDateTime.year

    return string
}

/**
 * Formats the given iso date string in the format "yyyy-mm-dd..." to "mm.dd.yyyy".
 *
 * Example:
 * "2021-01-01T00:00:00Z" will return "01.01.2021".
 *
 * @param date The date string to be formatted.
 * @return The formatted date string.
 * @throws IllegalArgumentException if the text cannot be parsed or the boundaries of [LocalDate] are exceeded.
 */
fun formatDateToMMDDYYYYDot(
    date: String,
    separator: String = ".",
): String = formatDateToMMDDYYYY(date, separator)

/**
 * Formats the given iso date string in the format "yyyy-mm-dd" to "mm.dd".
 * If the input date is null, an empty string is returned.
 * If [daysNumber] is not null, the method will add the specified number of days to the date.
 *
 * Example:
 * "2021-01-01" will return "01.01".
 *
 * @param date The date string to be formatted.
 * @param daysNumber The number of days to be added to the date. If null, no days will be added. Default is null.
 * @return The formatted date as a string.
 * @throws IllegalArgumentException if the text cannot be parsed or the boundaries of [LocalDate] are exceeded.
 */
fun formatIsoDateToMMDD(
    date: String?,
    daysNumber: Int? = null,
): String {
    if (date.isNullOrEmpty()) {
        return ""
    }

    var string = ""
    val localDate = LocalDate.parse(date.substring(0, 10)).plus(DatePeriod(days = daysNumber ?: 0))

    string += formatNumber(localDate.monthNumber) + "."

    string += formatNumber(localDate.dayOfMonth)

    return string
}

fun formatIsoDateRange(
    fromDate: String?,
    toDate: String?,
): ArrayList<HashMap<String, String>> {
    val formattedFromDate = formatIsoDateToMMDD(fromDate)
    val formattedToDate = formatIsoDateToMMDD(toDate)

    if (formattedFromDate == formattedToDate) {
        return arrayListOf(
            hashMapOf(
                "date" to formattedFromDate,
                "day" to isoDateToDayOfTheWeek(fromDate),
            ),
        )
    }

    val arrayList: ArrayList<HashMap<String, String>> = arrayListOf()

    if (formattedFromDate.isNotEmpty()) {
        arrayList.add(
            hashMapOf(
                "date" to formattedFromDate,
                "day" to isoDateToDayOfTheWeek(fromDate),
            ),
        )
    }

    if (formattedToDate.isNotEmpty()) {
        arrayList.add(
            hashMapOf(
                "date" to formattedToDate,
                "day" to isoDateToDayOfTheWeek(toDate),
            ),
        )
    }

    return arrayList
}

fun isoDateToDayOfTheWeek(
    date: String?,
): String {
    if (date.isNullOrEmpty()) {
        return ""
    }

    val localDate = LocalDate.parse(date.substring(0, 10))

    return localDate.dayOfWeek.name.lowercase().capitalizeFirstLetter()
}

/**
 * Formats the date range from [fromDate] to [toDate] in the following format:
 * "MMM DD - DD" if the dates are in the same month, or "MMM DD - MMM DD" if the dates are in different months.
 *
 * Example:
 *
 * "2021-01-01" to "2021-01-26" will return "Jan 01 - 26".
 * "2021-01-01" to "2021-02-02" will return "Jan 01 - Feb 02".
 *
 * If [fromDate] or [toDate] is null, an empty string is returned.
 *
 * @param fromDate The starting date of the range.
 * @param toDate The ending date of the range.
 * @return The formatted date range as a string.
 */
fun formatDateRange(
    fromDate: String?,
    toDate: String?,
): String {
    if (fromDate == null || toDate == null ||
        fromDate.length < 10 || toDate.length < 10
    ) return ""

    val sb = StringBuilder()
    val fromLocalDate = LocalDate.parse(fromDate)
    val toLocalDate = LocalDate.parse(toDate)

    val fromMonth = fromLocalDate.month.name.lowercase().capitalizeFirstLetter().take(3)
    val toMonth = toLocalDate.month.name.lowercase().capitalizeFirstLetter().take(3)

    sb.append(fromMonth + " " + formatNumber(fromLocalDate.dayOfMonth))
    sb.append(" - ")
    if (fromLocalDate.monthNumber == toLocalDate.monthNumber) {
        sb.append(formatNumber(toLocalDate.dayOfMonth))
    } else {
        sb.append(toMonth + " " + formatNumber(toLocalDate.dayOfMonth))
    }

    return sb.toString()
}

/**
 * Formats the given number as a string representation.
 * If the number < 10 we will add 0 at the beginning, ex: 9 -> "09"
 *
 * @param number The number to be formatted.
 * @return The formatted number as a string.
 */
fun formatNumber(number: Int): String = if (number in 0..9) "0$number" else "$number"

/**
 * Converts the given date string from the format "mm/dd/yyyy" to "yyyy-mm-dd".
 *
 * @param date the date string to be converted
 * @return the converted date string in the format "yyyy-mm-dd",
 *         or the original date string if the input format is invalid
 */
fun formatDateToYYYYMMDD(date: String): String {
    if (date.length < 10) return date

    val parts = date.substring(0, 10).split("/")

    if (parts.size != 3) return date

    return parts.reversed().toMutableList().apply {
        val month = removeLast()
        add(1, month)
    }.joinToString("-")
}

/**
 * Converts the given date string from the format "YYYY-MM-DD" to "MM/DD/YYYY".
 *
 * @param date The date string to be reversed and converted. It should be in the format "YYYY-MM-DD".
 * @return The reversed and converted date string in the format "MM/DD/YYYY".
 * If the input date is null or not in the correct format, null is returned.
 */
fun formatDateToMMDDYYYY(
    date: String?,
    separator: String = "/",
): String {
    if (date == null || date.length < 10) {
        return date.orEmpty()
    }

    val parts = date.substring(0, 10).split("-")

    if (parts.size != 3) return date

    return parts
        .reversed()
        .toMutableList()
        .apply {
            val day = removeFirst()
            add(1, day)
        }
        .joinToString(separator = separator)
}

/**
 * Returns a [LocalDate] object parsed from the given date string in the format "yyyy-mm-dd" or "mm/dd/yyyy".
 * If the input string is null, the method returns null.
 * If the input string does not match the expected format, the method returns null.
 *
 * @param date the date string to be parsed
 * @return the [LocalDate] object parsed from the input string,
 *         or null if the input string is null or does not match the expected format
 */
fun parseLocalDateOrNull(date: String?): LocalDate? {
    if (date == null || date.length < 10) {
        return null
    }

    val isoDate = if (date.contains('/')) formatDateToYYYYMMDD(date) else date

    return try {
        isoDate.substring(0, 10).toLocalDate()
    } catch (e: IllegalArgumentException) {
        null
    }
}

/**
 * Checks if the provided time string is a valid time format.
 *
 * @param time The time string to validate.
 * @return Returns true if the time string is valid, false otherwise.
 */
fun isValidTimeString(time: String?): Boolean = time != null && time.length == 5

/**
 * Converts a timestamp string to HH:MM format.
 *
 * @param timestamp the timestamp string to be converted
 * @return the converted string in HH:MM format
 */
fun formatTimestampToHHMM(timestamp: String): String {
    if (timestamp.length < 8) return timestamp
    val localTime = LocalTime.parse(timestamp.substring(0, 8))
    return "${
        formatNumber(localTime.hour)
    }:${
        formatNumber(localTime.minute)
    }"
}

fun formatTimestamptzToDateFullString(timestamp: String): String {
    if (timestamp.isEmpty())
        return ""

    val instant = Instant.fromEpochSeconds(timestamp.toLong())
    val localDate = instant.toLocalDateTime(TimeZone.currentSystemDefault()).date
    return localDate.formatToFullString()
}

fun formatTimestamptzToHHMM(
    timestamp: String,
    amPm: Boolean = true,
): String {
    if (timestamp.isEmpty())
        return ""

    // Define the desired time zone
    val timeZone = TimeZone.currentSystemDefault()

    // Parse the input date string to an Instant object
    val instant = Instant.fromEpochSeconds(timestamp.toLong())

    // Convert the Instant object to a LocalDateTime in the desired time zone
    val localTime = instant.toLocalDateTime(timeZone).time

    val hour =
        if (!amPm) localTime.hour else if (localTime.hour % 12 == 0) 12 else localTime.hour % 12 // Get hour in 12-hour format (1-12)
    val minute = localTime.minute.toString().padStart(2, '0') // Add leading zero for minutes

    val amPmLabel = if (localTime.hour < 12) "AM" else "PM"

    return "${
        formatNumber(hour)
    }:${
        minute
    }" + if (amPm) " $amPmLabel" else ""
}

fun formatTimestampToYYYYMMDDOrToHHMM(timestamp: String): String {
    val timeZone = TimeZone.currentSystemDefault()
    val instant = Instant.fromEpochSeconds(timestamp.toLong())

    val localDate = instant.toLocalDateTime(timeZone).date
    val todayDate = Clock.System.now().toLocalDateTime(timeZone).date

    val diffDays = todayDate.daysUntil(localDate)

    return if (diffDays < 0) {
        formatTimestamptzToDateFullString(timestamp)
    } else {
        formatTimestamptzToHHMM(timestamp)
    }
}

fun formatTimestampToYYYYMMDDAndToHHMM(timestamp: String): String {
    return "${formatTimestamptzToDateFullString(timestamp)}, ${formatTimestamptzToHHMM(timestamp, false)}"
}

/**
 * Converts the LocalDate to a string format following the pattern:
 * "DayOfWeek, Month DayOfMonth", ex: "Monday, January 01".
 *
 * @return the formatted date string
 */
fun LocalDate.formatToFullString(): String {
    val dayOfWeek = dayOfWeek.name.lowercase().capitalizeFirstLetter().substring(0, 3)
    val month = month.name.lowercase().capitalizeFirstLetter().substring(0, 3)
    val dayOfMonth = formatNumber(dayOfMonth)

    return "$dayOfWeek, $month $dayOfMonth"
}

fun LocalDateTime.formatToFullDateTime(): String {
    val dayOfWeek = dayOfWeek.name.lowercase().capitalizeFirstLetter().substring(0, 3)
    val month = month.name.lowercase().capitalizeFirstLetter().substring(0, 3)
    val day: Int = dayOfMonth
    val formattedMinute = formatNumber(minute)
    val formattedSecond = formatNumber(second)

    return "$dayOfWeek $day $month, $hour:$formattedMinute:$formattedSecond"
}

/**
 * Convert a timestamp string to the format "HH:MM Period".
 * The timestamp string should be in the format "HH:MM:SS".
 * The method will convert the timestamp to a 12-hour format.
 * If the timestamp string is invalid, the method will return the original string.
 * Example: "13:45:00" -> "01:45 PM"
 *
 * @param timestamp The timestamp string to convert. It should have a length of at least eight characters.
 * @return The converted timestamp in the format "HH:MM Period".
 */
fun formatTimestampToHHMMPeriod(timestamp: String): String {
    if (timestamp.length < 8) return timestamp

    val localTime = LocalTime.parse(timestamp.substring(0, 8))

    var hour = localTime.hour
    val minute = localTime.minute

    val period =
        if (hour >= 12) {
            "PM"
        } else {
            "AM"
        }

    if (hour > 12) {
        hour -= 12 // Convert to 12-hour format
    }

    if (hour == 0) {
        hour = 12
    }

    // Forcing single digit hours and minutes to have two digits
    val strHour = formatNumber(hour)

    val strMinute = formatNumber(minute)

    return "$strHour:$strMinute $period"
}

fun convertTimestampToTimeAgo(timestamp: String): String {
    val instant = Instant.parse(timestamp)
    val currentInstant = Clock.System.now()
    val difference = currentInstant.epochSeconds - instant.epochSeconds

    return when {
        difference < 60 -> "just now"
        difference < 3600 -> "${(difference / 60)} minutes ago"
        difference < 86400 -> "${(difference / 3600)} hours ago"
        else -> "${(difference / 86400)} days ago"
    }
}

fun formatDateToPrettyString(date: String?): String? {
    val todayDate = Clock.System.now()
        .toLocalDateTime(TimeZone.UTC).date.toString()

    val dateDiff = dateDiffInDays(
        fromDate = date,
        toDate = todayDate,
    )

    if (date.isNullOrEmpty() || dateDiff == null)
        return null

    return when (dateDiff) {
        0 -> "Today"
        -1 -> "Tomorrow"
        else -> formatIsoDateToMMDD(date)
    }
}

fun isDateOverDue(date: String?): Boolean {
    val todayDate = Clock.System.now()
        .toLocalDateTime(TimeZone.UTC).date.toString()

    val dateDiff = dateDiffInDays(
        fromDate = date,
        toDate = todayDate,
    )

    if (date.isNullOrEmpty() || dateDiff == null)
        return false

    return dateDiff >= 0
}

fun stringToLocalDate(dateString: String?): LocalDate? {
    if (dateString.isNullOrEmpty())
        return null

    val instant = Instant.parse(dateString.replace(" ", "T"))
    val timeZone = TimeZone.currentSystemDefault()

    val localDate = instant.toLocalDateTime(timeZone).date
    return localDate
}

fun stringToLocalDateTime(dateString: String?): LocalDateTime? {
    if (dateString.isNullOrEmpty())
        return null

    val instant = Instant.parse(dateString.replace(" ", "T"))
    val timeZone = TimeZone.currentSystemDefault()

    val localDateTime = instant.toLocalDateTime(timeZone)
    return localDateTime
}

fun stringToEpochSeconds(timestamp: String): Long {
    val instant = Instant.parse(timestamp)
    return instant.epochSeconds
}

fun getDayOfMonthWithSuffix(dayOfMonth: Int): String {
    val suffix =
        when (dayOfMonth) {
            1, 21, 31 ->
                "st"

            2, 22 ->
                "nd"

            3, 23 ->
                "rd"

            else ->
                "th"
        }

    return "$dayOfMonth$suffix"
}

fun isNow(dateString: String?, toleranceInSeconds: Long = 3): Boolean {
    if (dateString.isNullOrEmpty())
        return false

    val instant = Instant.parse(dateString.replace(" ", "T"))
    val timeZone = TimeZone.currentSystemDefault()

    val localDateTime = instant.toLocalDateTime(timeZone)
    val now = Clock.System.now().toLocalDateTime(timeZone)
    val duration = localDateTime.toInstant(timeZone).epochSeconds - now.toInstant(timeZone).epochSeconds

    return kotlin.math.abs(duration) <= toleranceInSeconds
}

fun getFutureDate(dateString: String?, daysToAdd: Int): String? {
    if (dateString.isNullOrEmpty())
        return null

    // Split the date string into components (Year, Month, Day)
    val dateParts = dateString.split("-")
    val year = dateParts[0].toInt()
    val month = dateParts[1].toInt()
    val day = dateParts[2].toInt()

    // Create LocalDate from components
    val localDate = LocalDate(year, month, day)

    // Add days
    val futureDate = localDate.plus(daysToAdd, DateTimeUnit.DAY)

    val dayOfMonth = futureDate.dayOfMonth
    val monthNumber = futureDate.monthNumber

    return "$monthNumber/$dayOfMonth"
}

val todayDate = Clock.System.now()
    .toLocalDateTime(TimeZone.UTC).date