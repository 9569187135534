package com.essntl.features.itinerary.data.repository

import com.essntl.core.supabase.*
import com.essntl.core.utils.repository.Repository
import com.essntl.features.itinerary.data.supabase.itineraries_to_confirm.ItineraryToConfirmSupabaseDataSource
import com.essntl.features.itinerary.data.supabase.itinerary.ItinerarySupabaseDataSource
import com.essntl.features.itinerary.data.supabase.itinerary.ItineraryDto
import com.essntl.features.itinerary.data.supabase.itinerary_service.ItineraryServiceDataSource
import com.essntl.features.itinerary.data.supabase.itinerary_service.ItineraryServiceDto
import com.essntl.features.itinerary.data.supabase.itinerary_service.toDto
import com.essntl.features.itinerary.data.supabase.itinerary_service.toRequestMap
import com.essntl.features.itinerary.domain.model.ItineraryFilterOptions
import com.essntl.features.itinerary.domain.model.ItineraryModel
import com.essntl.features.itinerary.domain.model.ItineraryServiceModel
import com.essntl.features.itinerary.domain.model.ItineraryToConfirmModel
import com.essntl.features.itinerary.domain.repository.ItineraryRepository
import com.essntl.features.proposal.data.supabase.fullproposal.FullProposalItineraryDto
import com.essntl.features.proposal.domain.model.fullproposal.FullProposalItineraryModel
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.functions.functions
import io.github.jan.supabase.postgrest.query.Order
import io.ktor.client.call.*
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.koin.core.annotation.Single

@Single
internal class ItineraryRepositoryImpl internal constructor(
    private val supabase: SupabaseClient,
    private val itinerarySupabase: ItinerarySupabaseDataSource,
    private val itineraryServiceSupabase: ItineraryServiceDataSource,
    private val itineraryToConfirmSupabase: ItineraryToConfirmSupabaseDataSource,
) : Repository(), ItineraryRepository {
    override suspend fun get(id: String): Result<ItineraryModel> =
        runCatching {
            val itinerary =
                itinerarySupabase.getById(
                    id = id,
                    columns = ItineraryDto.getColumns(),
                )

            itinerary.toModel()
        }

    override suspend fun getItineraryByShareCode(shareCode: String): Result<ItineraryModel?> =
        runCatching {
            itinerarySupabase
                .getOne(
                    columns = ItineraryDto.getColumns(),
                ) {
                    filter {
                        eq("share_code", shareCode)
                    }
                }
                ?.toModel()
        }

    override suspend fun getAll(): Result<List<ItineraryModel>> =
        runCatching {
            val itineraryList =
                itinerarySupabase.getAll(
                    columns = ItineraryDto.getColumns(),
                )

            itineraryList
                .map { itinerary ->
                    itinerary.toModel()
                }
        }

    override suspend fun getAll(
        page: Long,
        limit: Int,
        filterOptions: ItineraryFilterOptions,
    ): Result<List<ItineraryModel>> =
        runCatching {
            val itineraryList = itinerarySupabase.getAll(
                page = page,
                limit = limit,
                columns = ItineraryDto.getColumns(),
            ) {
                order("created_at", Order.DESCENDING)

                if (filterOptions.selectedProfileIds.isNotEmpty()) {
                    filter {
                        filterOptions.selectedProfileIds.forEach { profileId ->
                            eq(
                                "owner_id",
                                profileId,
                            )
                        }
                    }
                }
            }

            itineraryList
                .map { itinerary ->
                    itinerary.toModel()
                }
        }

    override suspend fun getAllByIds(ids: List<String>): Result<List<ItineraryModel>> =
        runCatching {
            itinerarySupabase
                .getByIds(
                    ids = ids,
                    columns = ItineraryDto.getColumns(),
                )
                .map { it.toModel() }
        }

    override suspend fun getItinerariesByProposalIds(proposalIds: List<String>): Result<List<ItineraryModel>> =
        runCatching {
            itinerarySupabase
                .getAll(
                    columns = ItineraryDto.getColumns(),
                ) {
                    filter {
                        or {
                            proposalIds.forEach { proposalId ->
                                eq("proposal_id", proposalId)
                            }
                        }
                    }
                }
                .map {
                    it.toModel()
                }
        }

    override suspend fun delete(model: ItineraryModel): Result<Unit> =
        runCatching {
            itinerarySupabase.delete(id = model.id)
        }

    override suspend fun getItineraryService(id: String): Result<ItineraryServiceModel> =
        runCatching {
            itineraryServiceSupabase
                .getById(
                    id = id,
                    columns = ItineraryServiceDto.getColumns(),
                )
                .toModel()
        }

    override suspend fun getItineraryServicesByIds(ids: List<String>): Result<List<ItineraryServiceModel>> =
        runCatching {
            itineraryServiceSupabase
                .getByIds(
                    ids = ids,
                    columns = ItineraryServiceDto.getColumns(),
                )
                .map { it.toModel() }
        }

    override suspend fun updateItineraryService(itineraryServiceModel: ItineraryServiceModel): Result<ItineraryServiceModel> =
        runCatching {
            val requestMap = itineraryServiceModel.toRequestMap()
            itineraryServiceSupabase
                .update(
                    id = itineraryServiceModel.id,
                    requestMap = requestMap,
                    columns = ItineraryServiceDto.getColumns(),
                ).toModel()
        }

    override suspend fun addAllItineraryService(itineraryServiceList: List<ItineraryServiceModel>): Result<List<ItineraryServiceModel>> =
        runCatching {
            itineraryServiceSupabase
                .insertAll<ItineraryServiceDto>(
                    requestMap = itineraryServiceList.map { it.toRequestMap(ignoreNullFields = false) },
                    columns = ItineraryServiceDto.getColumns(),
                ).map { it.toModel() }
        }

    override suspend fun getAllItineraryService(
        itineraryId: String,
        excludeStatus: ItineraryServiceModel.Status?,
    ): Result<List<ItineraryServiceModel>> =
        runCatching {
            itineraryServiceSupabase
                .getAll(
                    columns = ItineraryServiceDto.getColumns(),
                ) {
                    filter {
                        eq("itinerary_id", itineraryId)

                        if (excludeStatus != null)
                            neq("status", excludeStatus.toDto())
                    }
                    order("created_at", Order.DESCENDING)
                }
                .map { it.toModel() }
        }

    override suspend fun getAllItineraryServiceByStartDate(startDate: String): Result<List<ItineraryServiceModel>> =
        runCatching {
            itineraryServiceSupabase.getAll(columns = ItineraryServiceDto.getColumns()) {
                filter {
                    eq("start_date", startDate)
                }
                order("created_at", Order.DESCENDING)
            }.map { it.toModel() }
        }

    override suspend fun getAllItineraryServiceByDateRange(
        startDate: String,
        endDate: String,
    ): Result<List<ItineraryServiceModel>> =
        runCatching {
            itineraryServiceSupabase.getAll(columns = ItineraryServiceDto.getColumns()) {
                filter {
                    if (startDate.isNotEmpty())
                        gte("start_date", startDate)
                    if (endDate.isNotEmpty())
                        lte("end_date", endDate)
                }
                order("created_at", Order.DESCENDING)
            }.map { it.toModel() }
        }

    override suspend fun getAllItineraryToConfirm(
        filterOptions: ItineraryFilterOptions,
    ): Result<List<ItineraryToConfirmModel>> =
        runCatching {
            itineraryToConfirmSupabase
                .getAll {
                    if (filterOptions.selectedProfileIds.isNotEmpty()) {
                        filter {
                            filterOptions.selectedProfileIds.forEach { profileId ->
                                eq(
                                    "owner_id",
                                    profileId,
                                )
                            }
                        }
                    }
                }
                .map { it.toModel() }
        }

    override suspend fun getFullItineraryById(id: String): Result<FullProposalItineraryModel> =
        runCatching {
            supabase.functions.invoke(
                function = "proposal-data/itinerary",
                body = buildJsonObject {
                    put("itinerary_id", id)
                },
            ).body<FullProposalItineraryDto>()
                .toModel()
        }

    override suspend fun getFullItineraryByShareCode(shareCode: String): Result<FullProposalItineraryModel> =
        runCatching {
            supabase.functions.invoke(
                function = "proposal-data/itinerary",
                body = buildJsonObject {
                    put("share_code", shareCode)
                },
            ).body<FullProposalItineraryDto>()
                .toModel()
        }
}
