package com.essntl.features.proposal.domain.model.fullproposal.fullservice

data class FullServicesModel(
    val id: String,
    val additionalInfo: String,
    val grouped: Boolean,
    val startDate: String,
    val price: Int,
    val editedDescription: String,
    val service: FullServiceModel,
    val startTime: String?,
    val endTime: String?,
    val linkedId: String?,
    val index: Int?,
    val status: String?,
    val embeddedMap: String?,
    val embeddedContent: String?,
    val address: String?,
    val contactName: String?,
    val contactPhoneNumber: String?,
    val paid: Boolean?,
    val attachments: List<FullFileModel>?,
    val dynamicFields: List<FullDynamicModel>?,
)
