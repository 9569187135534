package client.pages

import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullDynamicModel
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullFileModel
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullServiceModel

data class ServiceUiState(
    val id: String,
    val service: FullServiceModel,
    val grouped: Boolean?,
    val additionalInfo: String?,
    val editedDescription: String?,
    val startDate: String,
    val startTime: String?,
    val endTime: String?,
    val index: Int?,
    val status: String?,
    val price: Int?,
    val address: String?,
    val contactName: String?,
    val contactPhoneNumber: String?,
    val dynamicFields: List<FullDynamicModel>?,
    val paid: Boolean?,
    val embeddedMap: String?,
    val embeddedContent: String?,
    val files: List<FullFileModel>?,
    val linkedId: String?,
    val hidePrices: Boolean,
) {
    enum class Status {
        Ready,
        POSent,
        Confirmed,
        TOChange,
        Cancelled,
        Completed,
    }
}
