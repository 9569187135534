package com.essntl.features.proposal.data.supabase.fullproposal.fullservice

import com.essntl.config.EssntlConfig
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullServiceModel
import kotlinx.serialization.Serializable

@Serializable
data class FullServiceDto(
    val title: String,
    val headline: String?,
    val images: List<String>?,
    val category: FullCategoryDto,
) {
    fun toModel() =
        FullServiceModel(
            title = title,
            headline = headline,
            category = category.toModel(),
            images = images?.map { url ->
                if (url.startsWith("http")) url else
                    "${EssntlConfig.supabaseUrl}/${EssntlConfig.supabaseStorageUrl}/$url"
            },
        )
}
