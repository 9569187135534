package com.essntl.core.supabase

import com.essntl.config.EssntlConfig
import com.essntl.core.supabase.error.handleErrors
import com.essntl.core.supabase.upload.upload
import com.essntl.core.utils.io.getKmpFile
import com.essntl.features.file.domain.model.FileModel
import com.mohamedrejeb.calf.core.PlatformContext
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.functions.functions
import io.github.jan.supabase.gotrue.PostgrestFilterDSL
import io.github.jan.supabase.postgrest.postgrest
import io.github.jan.supabase.postgrest.query.Columns
import io.github.jan.supabase.postgrest.query.Count
import io.github.jan.supabase.postgrest.query.Order
import io.github.jan.supabase.postgrest.query.PostgrestRequestBuilder
import io.github.jan.supabase.postgrest.query.filter.PostgrestFilterBuilder
import io.github.jan.supabase.realtime.*
import io.github.jan.supabase.storage.storage
import kotlinx.datetime.Clock
import kotlinx.serialization.json.JsonElement

interface SupabaseDataSource<T : Any> {
    val supabaseClient: SupabaseClient
    val tableName: String

    val postgrestBuilder get() = supabaseClient.postgrest[tableName]
    val storageBuilder get() = supabaseClient.storage
    val realtimeBuilder get() = supabaseClient.realtime
    val edgeFunctionsBuilder get() = supabaseClient.functions
}

/**
 * @param page starts at 1
 * @param limit defaults to 10
 */
suspend inline fun <reified T : Any> SupabaseDataSource<T>.getAll(
    page: Long,
    limit: Int = 10,
    columns: Columns = Columns.ALL,
    crossinline filter: @PostgrestFilterDSL PostgrestRequestBuilder.() -> Unit = {},
) = handleErrors {
    postgrestBuilder
        .select(
            columns = columns,
            request = {
                filter()

                val from = (page - 1) * limit
                val to = page * limit - 1

                range(
                    from = from,
                    to = to,
                )
            },
        )
        .decodeList<T>()
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.getAll(
    columns: Columns = Columns.ALL,
    crossinline filter: @PostgrestFilterDSL PostgrestRequestBuilder.() -> Unit = {
        order("created_at", Order.DESCENDING)
    },
) = handleErrors {
    postgrestBuilder.select(
        columns = columns,
        request = filter,
    ).decodeList<T>()
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.getOne(
    columns: Columns = Columns.ALL,
    crossinline filter: @PostgrestFilterDSL PostgrestRequestBuilder.() -> Unit,
) = handleErrors {
    postgrestBuilder.select(
        columns = columns,
        request = filter,
    ).decodeSingleOrNull<T>()
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.getById(
    id: String,
    columns: Columns = Columns.ALL,
) = handleErrors {
    postgrestBuilder.select(
        columns = columns,
    ) {
        filter {
            eq("id", id)
        }
    }.decodeSingle<T>()
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.getByIds(
    ids: List<String>,
    columns: Columns = Columns.ALL,
    crossinline filter: @PostgrestFilterDSL PostgrestFilterBuilder.() -> Unit = {},
) = handleErrors {
    postgrestBuilder.select(
        columns = columns,
    ) {
        filter {
            if (ids.isNotEmpty()) {
                or {
                    ids.forEach { id ->
                        eq("id", id)
                    }
                }

                filter()
            }
        }

        order("created_at", Order.DESCENDING)
    }.decodeList<T>()
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.getCount(
    crossinline filter: @PostgrestFilterDSL PostgrestRequestBuilder.() -> Unit = {},
) = handleErrors {
    postgrestBuilder
        .select(
            head = true,
        ) {
            filter()
            count(Count.EXACT)
        }
        .countOrNull()!!
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.insertAll(
    requestMap: List<Map<String, JsonElement>>,
    columns: Columns = Columns.ALL,
) =
    handleErrors {
        if (requestMap.isEmpty())
            return@handleErrors emptyList<T>()

        postgrestBuilder.insert(requestMap) {
            select(columns = columns)
            order("created_at", Order.DESCENDING)
        }.decodeList<T>()
    }

suspend inline fun <reified T : Any> SupabaseDataSource<T>.insert(
    requestMap: Map<String, JsonElement>,
    columns: Columns = Columns.ALL,
) =
    handleErrors {
        postgrestBuilder.insert(requestMap) {
            select(columns = columns)
        }.decodeSingle<T>()
    }

suspend inline fun <reified T : Any> SupabaseDataSource<T>.update(
    id: String,
    requestMap: Map<String, JsonElement>,
    columns: Columns = Columns.ALL,
) = handleErrors {
    postgrestBuilder.update(requestMap) {
        select(columns = columns)
        filter {
            eq("id", id)
        }
    }.decodeSingle<T>()
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.updateColumn(
    column: String,
    value: String,
    requestMap: Map<String, JsonElement>,
    columns: Columns = Columns.ALL,
) = handleErrors {
    postgrestBuilder.update(requestMap) {
        select(columns = columns)
        filter {
            eq(column, value)
        }
    }.decodeSingle<T>()
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.delete(id: String) =
    handleErrors {
        postgrestBuilder.delete {
            filter {
                eq("id", id)
            }
        }
    }

suspend inline fun <reified T : Any> SupabaseDataSource<T>.deleteAll(ids: List<String>) =
    handleErrors {
        if (ids.isEmpty())
            return@handleErrors Unit

        postgrestBuilder.delete {
            filter {
                or {
                    ids.forEach { id ->
                        eq("id", id)
                    }
                }
            }
        }
    }

suspend inline fun <reified T : Any> SupabaseDataSource<T>.delete(
    crossinline filter: @PostgrestFilterDSL PostgrestRequestBuilder.() -> Unit,
) = handleErrors {
    postgrestBuilder.delete(request = filter)
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.uploadImage(
    images: List<FileModel>,
    bucketName: String,
    folderName: String,
    context: PlatformContext,
): List<String> {
    return handleErrors {
        val bucket = storageBuilder.from(bucketName)
        images.map {
            val result =
                bucket.upload(
                    path = "$folderName/${Clock.System.now()}" +
                        (it.name?.replace(" ", "_")?.replace("-", "_") ?: ""),
                    kmpFile = getKmpFile(it.url),
                    context = context,
                    upsert = false,
                )
            println("Image uploaded: $result")
            "${EssntlConfig.supabaseUrl}/${EssntlConfig.supabaseStorageUrl}/$result"
        }
    }
}


suspend inline fun <reified T : Any> SupabaseDataSource<T>.listFiles(bucketName: String):
    List<String> {
    return handleErrors {
        val bucket = storageBuilder.from(bucketName)
        val files = bucket.list()
        files.map { it.name }
    }
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.downloadFile(
    path: String,
    bucketName: String,
): ByteArray {
    return handleErrors {
        val bucket = storageBuilder.from(bucketName)
        bucket.downloadPublic(path)
    }
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.deleteImage(
    imagesUrl: List<String>,
    bucketName: String = "images",
) = handleErrors {
    val bucket = storageBuilder.from(bucketName)
    imagesUrl.map {
        val path = it.substringAfterLast("public/$bucketName/")
        bucket.delete(path)
    }
}


suspend inline fun <reified T : Any> SupabaseDataSource<T>.getChannel() = handleErrors {
    supabaseClient.channel(tableName)
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.removeChannel(
    channel: RealtimeChannel,
) = handleErrors {
    realtimeBuilder.removeChannel(channel)
}

suspend inline fun <reified T : Any> SupabaseDataSource<T>.invokeFunction(
    edgeFunctionName: String,
    body: Map<String, JsonElement>,
) = handleErrors {
    edgeFunctionsBuilder.invoke(
        function = edgeFunctionName,
        body = body,
    )
}