package com.essntl.features.proposal.domain.model.fullproposal

data class FullClientModel(
    val salutation: String?,
    val prettyName: String,
) {
    companion object {
        val empty =
            FullClientModel(
                salutation = null,
                prettyName = "",
            )
    }
}
