package client.termsandconditions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.essntl.features.proposal.domain.model.fullproposal.FullProposalItineraryModel
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px

@Composable
fun TermsAndConditionsContent(
    fullProposal: FullProposalItineraryModel,
    initialIsExpanded: Boolean = true,
) {
    val breakpoint = rememberBreakpoint()
    var isExpanded by remember {
        mutableStateOf(initialIsExpanded)
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(
                leftRight =
                    if (breakpoint < Breakpoint.MD)
                        10.px
                    else
                        20.px,
            ),
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .borderRadius(28.px)
                .overflow(Overflow.Hidden)
                .backgroundColor(Colors.White),
        ) {
            TermsAndConditionsHeader(
                fullProposal = fullProposal,
                isExpanded = isExpanded,
                onToggleExpand = { isExpanded = !isExpanded },
            )

            if (isExpanded)
                TermsAndConditionsBody()
        }
    }
}