package com.essntl.features.proposal.data.supabase.fullproposal.fullservice

import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullDynamicModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FullDynamicDto(
    @SerialName("field_name")
    val fieldName: String,
    @SerialName("show_on_itinerary")
    val showOnItinerary: Boolean,
    val value: String,
) {
    fun toModel() =
        FullDynamicModel(
            fieldName = fieldName,
            showOnItinerary = showOnItinerary,
            value = value,
        )
}
