package com.essntl.features.itinerary.data.supabase.itineraries_to_confirm

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class ItineraryToConfirmSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<ItineraryToConfirmDto> {
    override val tableName: String = "itineraries_to_confirm"
}
