package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_proposal_data_di_ProposalModule : Module = module {
	single() { com.essntl.features.proposal.data.repository.ProposalRepositoryImpl(supabase=get(),proposalDataSource=get(),proposalHistoryDataSource=get(),proposalServiceDataSource=get(),datastoreManager=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.proposal.domain.repository.ProposalRepository::class))
	single() { com.essntl.features.proposal.data.supabase.proposal.ProposalDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
	single() { com.essntl.features.proposal.data.supabase.proposalhistory.ProposalHistoryDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
	single() { com.essntl.features.proposal.data.supabase.proposalservice.ProposalServiceDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.proposal.data.di.ProposalModule.module : org.koin.core.module.Module get() = com_essntl_features_proposal_data_di_ProposalModule