package com.essntl.features.proposal.data.supabase.fullproposal.fullservice

import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullFileModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FullFileDto(
    val id: String,
    val name: String,
    val url: String,
    @SerialName("created_at")
    val createdAt: String,
) {
    fun toModel() =
        FullFileModel(
            id = id,
            name = name,
            url = url,
            createdAt = createdAt,
        )
}
