package com.essntl.features.proposal.data.supabase.fullproposal

import com.essntl.features.proposal.data.supabase.fullproposal.fullservice.FullServicesDto
import com.essntl.features.proposal.domain.model.fullproposal.FullProposalItineraryModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FullProposalItineraryDto(
    val title: String,
    val headline: String? = null,
    @SerialName("from_date")
    val fromDate: String,
    @SerialName("to_date")
    val toDate: String,
    val areas: List<FullAreaDto>,
    @SerialName("primary_client")
    val primaryClient: FullClientDto? = null,
    @SerialName("travel_agent")
    val travelAgent: FullTravelAgentDto? = null,
    @SerialName("cover_image_url")
    val coverImageUrl: String? = null,
    val services: List<FullServicesDto>,
    @SerialName("hide_prices")
    val hidePrices: Boolean?,
) {
    fun toModel() =
        FullProposalItineraryModel(
            title = title,
            headline = headline,
            fromDate = fromDate,
            toDate = toDate,
            areas = areas.map { it.toModel() },
            primaryClient = primaryClient?.toModel(),
            travelAgent = travelAgent?.toModel(),
            coverImageUrl = coverImageUrl,
            services = services.map { it.toModel() },
            hidePrices = hidePrices ?: false,
        )
}
