package com.essntl.features.itinerary.data.supabase.itinerary_service

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class ItineraryServiceDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<ItineraryServiceDto> {
    override val tableName: String = "itinerary_service"
}