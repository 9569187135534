package client.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import client.pages.ProposalItineraryEvent
import client.pages.rememberProposalViewModel
import core.config.buildFlavor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.Text

@Composable
fun ItineraryScreenContent(
    shareCode: String? = null,
    itineraryId: String? = null,
) {
    val viewModel = rememberProposalViewModel()
    val state by viewModel.state.collectAsState()

    itineraryId?.let {
        LaunchedEffect(Unit) {
            viewModel.onEvent(
                ProposalItineraryEvent.GetPreviewItinerary(id = it),
            )
        }
    }

    shareCode?.let {
        LaunchedEffect(Unit) {
            viewModel.onEvent(
                ProposalItineraryEvent.GetItinerary(shareCode = it),
            )
        }
    }

    // LaunchedEffect(state.getArea.data) {
    //   document.title = if (buildFlavor.isDemo) "Essntl" else "Be Essential"
    // state.getArea.data?.country.let { country ->
    //   document.title = if (buildFlavor.isDemo) "Essntl" else "Your Be Essential Itinerary to $country"
    // }
    // }

    val fullProposal = state.getFullProposal.data

    Box(
        contentAlignment = Alignment.TopStart,
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(Color.rgb(0xF2EFEA)),
    ) {
        if (fullProposal == null)
            Text(
                value = "Loading...",
            )
        else
            ProposalScreenContent(
                fullProposal = fullProposal,
                allProposalService = state.itineraryServicesToUiStateList(fullProposal),
                onDownloadFile = {
                    viewModel.onEvent(
                        ProposalItineraryEvent.DownloadFile(it),
                    )
                },
                isItinerary = true,
            )
    }
}