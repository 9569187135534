package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_essntl_features_itinerary_data_di_ItineraryModule : Module = module {
	single() { com.essntl.features.itinerary.data.repository.ItineraryRepositoryImpl(supabase=get(),itinerarySupabase=get(),itineraryServiceSupabase=get(),itineraryToConfirmSupabase=get()) } binds(arrayOf(com.essntl.core.utils.repository.Repository::class,com.essntl.features.itinerary.domain.repository.ItineraryRepository::class))
	single() { com.essntl.features.itinerary.data.supabase.itineraries_to_confirm.ItineraryToConfirmSupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
	single() { com.essntl.features.itinerary.data.supabase.itinerary.ItinerarySupabaseDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
	single() { com.essntl.features.itinerary.data.supabase.itinerary_service.ItineraryServiceDataSource(supabaseClient=get()) } bind(com.essntl.core.supabase.SupabaseDataSource::class)
}
public val com.essntl.features.itinerary.data.di.ItineraryModule.module : org.koin.core.module.Module get() = com_essntl_features_itinerary_data_di_ItineraryModule