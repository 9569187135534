package com.essntl.core.utils.viewmodel

data class State<T>(
    val isLoading: Boolean = false,
    val isFailure: Boolean = false,
    val isSuccess: Boolean = false,
    val data: T? = null,
    val errorMessage: String? = null,
) {
    companion object {
        fun <T> initial() = State<T>()

        fun <T> loading(data: T? = null) =
            State(
                isLoading = true,
                data = data,
            )

        fun <T> failure(data: T? = null, error: String? = null) =
            State(
                isFailure = true,
                data = data,
                errorMessage = error,
            )

        fun <T> success(data: T) = State(isSuccess = true, data = data)
    }
}
