package com.essntl.core.utils.utils.windowmanager

import cafe.adriel.voyager.core.screen.Screen
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow

class WindowMangerImpl : WindowManager {
    private val _screenFlow = MutableSharedFlow<Screen>()

    override val screenFlow = _screenFlow.asSharedFlow()

    override suspend fun emitScreen(screen: Screen) {
        _screenFlow.emit(screen)
    }
}