package client.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.essntl.core.utils.utils.loadingmanager.LoadingManager
import com.essntl.core.utils.viewmodel.ViewModel
import com.essntl.core.utils.viewmodel.getData
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.file.domain.repository.FileRepository
import com.essntl.features.itinerary.domain.repository.ItineraryRepository
import com.essntl.features.proposal.domain.repository.ProposalRepository
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.compose.koinInject
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

@Composable
internal fun rememberProposalViewModel(): ProposalItineraryViewModel {
    val scope = rememberCoroutineScope()
    val proposalRepository = koinInject<ProposalRepository>()
    val fileRepository = koinInject<FileRepository>()
    val itineraryRepository = koinInject<ItineraryRepository>()
    val loadingManager = koinInject<LoadingManager>()

    return remember {
        ProposalItineraryViewModel(
            scope = scope,
            proposalRepository = proposalRepository,
            itineraryRepository = itineraryRepository,
            fileRepository = fileRepository,
            loadingManager = loadingManager,
        )
    }
}

class ProposalItineraryViewModel(
    override val scope: CoroutineScope,
    override val loadingManager: LoadingManager,
    private val proposalRepository: ProposalRepository,
    private val itineraryRepository: ItineraryRepository,
    private val fileRepository: FileRepository,
) : ViewModel {

    private val _state = MutableStateFlow(ProposalState())
    val state = _state.asStateFlow()

    fun onEvent(event: ProposalItineraryEvent) {
        when (event) {
            is ProposalItineraryEvent.GetProposalPreview -> getProposalPreview(event)

            is ProposalItineraryEvent.GetPreviewItinerary -> getPreviewItinerary(event)

            is ProposalItineraryEvent.GetItinerary -> getItinerary(event)

            is ProposalItineraryEvent.GetProposal -> getProposal(event)

            is ProposalItineraryEvent.GetProposalHistory -> getProposalHistory(event)

            is ProposalItineraryEvent.DownloadFile -> downloadFiles(event)

            is ProposalItineraryEvent.ClearState -> _state.value = ProposalState()
        }
    }

    private fun getPreviewItinerary(event: ProposalItineraryEvent.GetPreviewItinerary) {
        getData(
            getData = { itineraryRepository.getFullItineraryById(event.id) },
            getState = { _state.value.getFullProposal },
            setState = { _state.value = _state.value.copy(getFullProposal = it) },
        )
    }

    private fun getItinerary(event: ProposalItineraryEvent.GetItinerary) {
        getData(
            getData = { itineraryRepository.getFullItineraryByShareCode(event.shareCode) },
            getState = { _state.value.getFullProposal },
            setState = { _state.value = _state.value.copy(getFullProposal = it) },
        )
    }

    private fun getProposalPreview(event: ProposalItineraryEvent.GetProposalPreview) {
        getData(
            getData = { proposalRepository.getFullProposalById(event.id) },
            getState = { _state.value.getFullProposal },
            setState = { _state.value = _state.value.copy(getFullProposal = it) },
        )
    }

    private fun getProposal(event: ProposalItineraryEvent.GetProposal) {
        getData(
            getData = { proposalRepository.getFullProposalByShareCode(event.shareCode) },
            getState = { _state.value.getFullProposal },
            setState = { _state.value = _state.value.copy(getFullProposal = it) },
        )
    }

    private fun getProposalHistory(event: ProposalItineraryEvent.GetProposalHistory) {
        getData(
            getData = { proposalRepository.getFullProposalHistory(event.shareCode, event.version) },
            getState = { _state.value.getFullProposal },
            setState = { _state.value = _state.value.copy(getFullProposal = it) },
        )
    }

    private fun downloadFiles(event: ProposalItineraryEvent.DownloadFile) =
        scope.launch {
            fileRepository.downloadFiles(
                bucketName = "files",
                file = FileModel.empty.copy(
                    id = event.file.id,
                    name = event.file.name,
                    url = event.file.url,
                ),
            ).onSuccess { byteArray ->
                val blob = Blob(arrayOf(byteArray), BlobPropertyBag("*/*"))
                val link = document.createElement("a") as HTMLAnchorElement
                link.href = URL.createObjectURL(blob)
                link.download = event.file.name ?: "file.txt"
                link.click()
            }.onFailure { throwable ->
                println("Download Fail: $throwable")
            }
        }
}