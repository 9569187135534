package client.pages.components

import androidx.compose.runtime.Composable
import client.components.widgets.EsSmallIcon
import client.components.widgets.SvgEssntl
import client.toSitePalette
import client.ui.EsIcons
import client.ui.iconSrc
import client.ui.imageSrc
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontStyle
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import core.config.buildFlavor
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px

@Composable
fun ProposalFooter() {
    val sitePalette = ColorMode.current.toSitePalette()
    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier
            .padding(
                leftRight =
                if (breakpoint < Breakpoint.MD)
                    10.px
                else
                    20.px,
            )
            .fillMaxWidth()
            .margin(top = 10.px),
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(sitePalette.background.secondary)
                .borderRadius(
                    topLeft = 40.px,
                    topRight = 40.px,
                )
                .padding(
                    topBottom = 10.px,
                    leftRight = 20.px,
                ),
        ) {
            if (!buildFlavor.isDemo) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceEvenly,
                    modifier = Modifier
                        .fillMaxWidth(),
                ) {
                    Image(
                        src = imageSrc(EsIcons.BeLogoFull),
                        height = if (breakpoint < Breakpoint.MD) 40 else 50,
                        modifier = Modifier
                            .onClick { },
                    )

                    if (breakpoint >= Breakpoint.MD) {
                        Spacer()

                        ProposalFooterContactInformation()

                        Spacer()
                    }
                }

                if (breakpoint < Breakpoint.MD) {
                    ProposalFooterContactInformation()
                }
            }

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .color(sitePalette.text.primary)
                    .fontSize(14.px)
                    .lineHeight(20.px)
                    .fontStyle(FontStyle.Italic)
                    .fontWeight(FontWeight.Medium)
                    .margin(
                        top = 8.px,
                        bottom = 8.px,
                    ),
            ) {
                SpanText(
                    text = "Powered by",
                    modifier = Modifier
                        .margin(
                            right = 5.px,
                        ),
                )

                SvgEssntl(
                    modifier = Modifier
                        .size(17.px),
                    color = "black",
                )

                SpanText(
                    text = "Essntl",
                )
            }
        }
    }
}

@Composable
fun ProposalFooterContactInformation() {
    val sitePalette = ColorMode.current.toSitePalette()
    Spacer()
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.margin(top = 8.px),
    ) {
        EsSmallIcon(iconRes = iconSrc(EsIcons.OfficePhone))
        SpanText(
            text = "+1 347 440 6921",
            modifier = Modifier
                .color(sitePalette.text.primary)
                .fontSize(16.px)
                .textAlign(TextAlign.Center)
                .lineHeight(20.px)
                .fontWeight(FontWeight.SemiBold)
                .margin(left = 8.px),
        )
    }
    Spacer()
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.margin(top = 8.px),
    ) {
        EsSmallIcon(iconRes = iconSrc(EsIcons.SupportContact))
        SpanText(
            text = "24/7",
            modifier = Modifier
                .color(sitePalette.text.primary)
                .fontSize(14.px)
                .textAlign(TextAlign.Center)
                .lineHeight(20.px)
                .fontWeight(FontWeight.SemiBold)
        )

        SpanText(
            text = "+33 6 46 59 08 70",
            modifier = Modifier
                .color(sitePalette.text.primary)
                .fontSize(16.px)
                .textAlign(TextAlign.Center)
                .lineHeight(20.px)
                .fontWeight(FontWeight.SemiBold)
                .margin(left = 8.px),
        )
    }
    Spacer()
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .margin(top = 8.px)
            .cursor(Cursor.Pointer)
            .onClick {
                window.open("mailto:ask@beessential.club")
            },
    ) {
        EsSmallIcon(iconRes = iconSrc(EsIcons.Email))
        SpanText(
            text = "ask@beessential.club",
            modifier = Modifier
                .color(sitePalette.text.primary)
                .fontSize(16.px)
                .textAlign(TextAlign.Center)
                .lineHeight(20.px)
                .fontWeight(FontWeight.SemiBold)
                .margin(left = 8.px),
        )
    }
    Spacer()
}