package com.essntl.features.proposal.domain.model.fullproposal

data class FullTravelAgentModel(
    val firstName: String,
    val lastName: String?,
    val agency: String?,
    val logoImageUrl: String?,
) {
    companion object {
        val empty =
            FullTravelAgentModel(
                firstName = "",
                agency = null,
                lastName = null,
                logoImageUrl = null,
            )
    }
}
