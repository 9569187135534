package com.essntl.features.proposal.data.supabase.fullproposal.fullservice

import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullServicesModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FullServicesDto(
    val id: String,
    @SerialName("additional_info")
    val additionalInfo: String,
    val grouped: Boolean,
    @SerialName("start_date")
    val startDate: String,
    @SerialName("start_time")
    val startTime: String? = null,
    @SerialName("end_time")
    val endTime: String? = null,
    @SerialName("linked_id")
    val linkedId: String? = null,
    val price: Int,
    val index: Int? = null,
    val status: String? = null,
    @SerialName("edited_description")
    val editedDescription: String,
    val service: FullServiceDto,
    @SerialName("embedded_map")
    val embeddedMap: String? = null,
    @SerialName("embedded_content")
    val embeddedContent: String? = null,
    val address: String? = null,
    @SerialName("contact_name")
    val contactName: String? = null,
    @SerialName("contact_phone_number")
    val contactPhoneNumber: String? = null,
    val paid: Boolean? = null,
    val attachments: List<FullFileDto>? = null,
    @SerialName("dynamic_fields")
    val dynamicFields: List<FullDynamicDto>? = null,
) {
    fun toModel() =
        FullServicesModel(
            id = id,
            additionalInfo = additionalInfo,
            grouped = grouped,
            startDate = startDate,
            price = price,
            editedDescription = editedDescription,
            service = service.toModel(),
            startTime = startTime,
            endTime = endTime,
            linkedId = linkedId,
            index = index,
            status = status,
            embeddedMap = embeddedMap,
            embeddedContent = embeddedContent,
            address = address,
            contactName = contactName,
            contactPhoneNumber = contactPhoneNumber,
            paid = paid,
            attachments = attachments?.map { it.toModel() },
            dynamicFields = dynamicFields?.map { it.toModel() },
        )
}
