package client.pages.components.hotel

import androidx.compose.runtime.*
import client.pages.components.service.DefaultServiceImage
import client.toSitePalette
import client.ui.spacing
import com.essntl.features.category.domain.model.CategoryModel
import com.essntl.features.file.domain.model.FileModel
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullCategoryModel
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import kotlin.math.min

@Composable
fun HotelPager(
    pageList: List<String?>,
    category: FullCategoryModel?,
    onOpenImageFullScreen: (imageList: List<String>, imageIndex: Int) -> Unit,
    modifier: Modifier = Modifier,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    var selectedPage by remember { mutableStateOf(0) }

    Box(
        modifier = modifier
            .overflow(Overflow.Hidden)
    ) {
        pageList.forEachIndexed { index, page ->
            DefaultServiceImage(
                serviceImage = page,
                category = category,
                onImageClicked = {
                    val list = pageList.filterNotNull()
                    onOpenImageFullScreen(list, min(index, list.lastIndex))
                },
                modifier = Modifier
                    .fillMaxSize()
                    .display(
                        if (index == selectedPage)
                            DisplayStyle.Block
                        else
                            DisplayStyle.None
                    )
            )
//            Box(
//                modifier = Modifier
//                    .fillMaxSize()
//                    .then(
//                        if (page.)
//                            Modifier
//                                .backgroundColor(Colors.LightGrey)
//                        else
//                            Modifier
//                                .backgroundImage(url(page))
//                                .backgroundSize(BackgroundSize.Cover)
//                                .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
//                    )
//                    .display(
//                        if (index == selectedPage)
//                            DisplayStyle.Block
//                        else
//                            DisplayStyle.None
//                    )
//            )
        }

        if (pageList.size > 1)
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .align(Alignment.BottomCenter)
                    .padding(8.px)
            ) {
                pageList.indices.forEach { index ->
                    Box(
                        modifier = Modifier
                            .size(
                                if (index == selectedPage)
                                    1.5f.spacing
                                else
                                    1.spacing,
                            )
                            .borderRadius(100.percent)
                            .backgroundColor(
                                if (index == selectedPage)
                                    sitePalette.icon.primary
                                else
                                    sitePalette.icon.contrast
                            )
                            .then(
                                if (index == selectedPage)
                                    Modifier
                                else
                                    Modifier
                                        .onClick {
                                            selectedPage = index
                                        }
                            )
                            .margin(
                                right =
                                    if (index < pageList.lastIndex)
                                        .5f.spacing
                                    else
                                        0.px
                            )
                    )
                }
            }
    }
}