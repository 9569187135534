package com.essntl.features.service.data.supabase.extraservice

import com.essntl.core.supabase.SupabaseDataSource
import io.github.jan.supabase.SupabaseClient
import org.koin.core.annotation.Single

@Single
internal class ExtraServiceSupabaseDataSource(
    override val supabaseClient: SupabaseClient,
) : SupabaseDataSource<ExtraServiceDto> {
    override val tableName: String = "extra_service"
}
