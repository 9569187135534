package client.pages.components

import androidx.compose.runtime.*
import client.components.widgets.EsSmallOutlineIconButton
import client.pages.ServiceUiState
import client.ui.EsIcons
import client.ui.iconSrc
import com.varabyte.kobweb.browser.dom.observers.ResizeObserver
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

@Composable
fun ItineraryDayInfosCollapsed(
    isExpanded: Boolean,
    allDayProposalService: List<ServiceUiState>,
    onProposalServiceSelected: (ServiceUiState) -> Unit,
    modifier: Modifier = Modifier,
) {
    val breakpoint = rememberBreakpoint()

    var canScrollLeft by remember { mutableStateOf(false) }
    var canScrollRight by remember { mutableStateOf(false) }

    LaunchedEffect(isExpanded) {
        val element = document.getElementById("collapsed-service-list") ?: return@LaunchedEffect

        ResizeObserver { _ ->
            canScrollLeft = element.scrollLeft > 10
            canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
        }.observe(element)

        element.addEventListener(
            "scroll",
            { _ ->
                canScrollLeft = element.scrollLeft > 10
                canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
            },
        )

        delay(1000)

        canScrollLeft = element.scrollLeft > 10
        canScrollRight = element.scrollLeft < element.scrollWidth - element.clientWidth - 10
    }

    Box(
        modifier = modifier
            .width(100.percent)
            .padding(
                topBottom = if (breakpoint < Breakpoint.MD) 14.px else 32.px,
            ),
    ) {
        Div(
            attrs = {
                id("collapsed-service-list")

                style {
                    width(100.percent)
                    display(DisplayStyle.Flex)
                    flex(1)
                    overflowX(Overflow.Auto)
                    whiteSpace(WhiteSpace.NoWrap)
                }
            },
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth(),
            ) {
                val leftRightMargin = if (breakpoint < Breakpoint.MD) 14.px else 32.px

                allDayProposalService.forEachIndexed { index, proposalService ->
                    val category = proposalService.service.category

                    val allServiceImage = remember(proposalService.service.images) {
                        proposalService.service.images
                    }

                    ProposalCollapsedService(
                        number = index + 1,
                        service = proposalService.service,
                        category = category,
                        allImage = allServiceImage.orEmpty(),
                        proposalServiceCount = allDayProposalService.size,
                        modifier = Modifier
                            .padding(
                                left = if (index == 0) leftRightMargin else 0.px,
                                right = if (index == allDayProposalService.lastIndex) leftRightMargin else 0.px,
                            )
                            .onClick {
                                onProposalServiceSelected(proposalService)
                            },
                    )
                }

                Box(
                    modifier = Modifier
                        .width(if (breakpoint < Breakpoint.MD) 14.px else 32.px),
                )
            }
        }

        if (canScrollLeft)
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier
                    .height(100.percent)
                    .align(Alignment.CenterStart)
                    .padding(10.px)
                    .zIndex(2),
            ) {
                EsSmallOutlineIconButton(
                    onClick = {
                        val element = document.getElementById("collapsed-service-list")
                            ?: return@EsSmallOutlineIconButton

                        element.scrollTo(
                            ScrollToOptions(
                                left = element.scrollLeft - 360.0,
                                behavior = ScrollBehavior.SMOOTH,
                            ),
                        )
                    },
                    iconRes = iconSrc(EsIcons.ArrowLeft),
                )
            }

        if (canScrollRight)
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier
                    .height(100.percent)
                    .align(Alignment.CenterEnd)
                    .padding(10.px)
                    .zIndex(2),
            ) {
                EsSmallOutlineIconButton(
                    onClick = {
                        val element = document.getElementById("collapsed-service-list")
                            ?: return@EsSmallOutlineIconButton

                        element.scrollTo(
                            ScrollToOptions(
                                left = element.scrollLeft + 360.0,
                                behavior = ScrollBehavior.SMOOTH,
                            ),
                        )
                    },
                    iconRes = iconSrc(EsIcons.ArrowRight),
                )
            }
    }
}