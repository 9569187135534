package com.essntl.features.area.data.repository

import com.essntl.core.supabase.*
import com.essntl.core.utils.repository.Repository
import com.essntl.features.area.data.supabase.AreaDto
import com.essntl.features.area.data.supabase.AreaSupabaseDataSource
import com.essntl.features.area.data.supabase.toRequestMap
import com.essntl.features.area.domain.model.AreaModel
import com.essntl.features.area.domain.repository.AreaRepository
import org.koin.core.annotation.Single

@Single
class AreaRepositoryImpl internal constructor(
    private val supabase: AreaSupabaseDataSource,
) : Repository(), AreaRepository {

    override suspend fun get(areaId: String): Result<AreaModel> =
        runCatching {
            supabase
                .getById(
                    id = areaId,
                    columns = AreaDto.getColumns(),
                ).toModel()
        }

    override suspend fun getAll(): Result<List<AreaModel>> =
        runCatching {
            supabase
                .getAll()
                .map {
                    it.toModel()
                }
        }

    override suspend fun insert(area: AreaModel): Result<AreaModel> =
        runCatching {
            supabase
                .insert(
                    requestMap = area.toRequestMap(),
                    columns = AreaDto.getColumns(),
                ).toModel()
        }

    override suspend fun update(area: AreaModel): Result<AreaModel> =
        runCatching {
            supabase
                .update(
                    id = area.id,
                    requestMap = area.toRequestMap(),
                    columns = AreaDto.getColumns(),
                ).toModel()
        }

    override suspend fun delete(area: AreaModel): Result<Unit> =
        runCatching {
            supabase.delete(id = area.id)
        }

    override suspend fun getCountAreasByCountry(countryId: String): Result<Long> =
        runCatching {
            supabase.getCount {
                filter {
                    eq("country_id", countryId)
                }
            }
        }

    override suspend fun getCountAreasByName(name: String): Result<Long> =
    runCatching {
        supabase.getCount {
            filter {
                ilike("name", name)
            }
        }
    }
}
