package com.essntl.features.travelagent.data.repository

import com.essntl.core.supabase.delete
import com.essntl.core.supabase.getAll
import com.essntl.core.supabase.getById
import com.essntl.core.supabase.getByIds
import com.essntl.core.supabase.getCount
import com.essntl.core.supabase.insert
import com.essntl.core.supabase.update
import com.essntl.core.utils.repository.Repository
import com.essntl.features.travelagent.data.supabase.TravelAgentSupabaseDataSource
import com.essntl.features.travelagent.data.supabase.toRequestMap
import com.essntl.features.travelagent.domain.model.TravelAgentModel
import com.essntl.features.travelagent.domain.repository.TravelAgentRepository
import io.github.jan.supabase.postgrest.query.Order
import org.koin.core.annotation.Single

@Single
internal class TravelAgentRepositoryImpl(
    private val supabase: TravelAgentSupabaseDataSource,
) : Repository(), TravelAgentRepository {
    override suspend fun getById(id: String): Result<TravelAgentModel> =
        runCatching {
            supabase
                .getById(id)
                .toModel()
        }

    override suspend fun getAll(
        page: Long,
        limit: Int,
        query: String,
    ): Result<List<TravelAgentModel>> =
        runCatching {
            supabase
                .getAll(page = page, limit = limit) {
                    if (query.isNotEmpty() && !query.contains(" ")) {
                        filter {
                            or {
                                ilike("first_name", "%$query%")
                                ilike("last_name", "%$query%")
                                ilike("agency", "%$query%")
                            }
                        }
                    } else if (query.isNotEmpty() && query.split(" ").count() > 1) {
                        val searchTerms = query.split(" ")
                        filter {
                            and {
                                ilike("first_name", "%${searchTerms[0]}%")

                                or {
                                    ilike("last_name", "%${searchTerms[1]}%")
                                    ilike("agency", "%${searchTerms[1]}%")
                                }
                            }
                        }
                    }

                    order("created_at", Order.DESCENDING)
                }
                .map { it.toModel() }
        }

    override suspend fun insert(travelAgent: TravelAgentModel): Result<TravelAgentModel> =
        runCatching {
            supabase
                .insert(travelAgent.toRequestMap())
                .toModel()
        }

    override suspend fun update(travelAgent: TravelAgentModel): Result<TravelAgentModel> =
        runCatching {
            supabase
                .update(id = travelAgent.id, travelAgent.toRequestMap())
                .toModel()
        }

    override suspend fun delete(travelAgent: TravelAgentModel): Result<Unit> =
        runCatching {
            supabase
                .delete(travelAgent.id)
        }

    override suspend fun getByPhoneNumbers(phoneNumbers: Set<String>): Result<List<TravelAgentModel>> =
        runCatching {
            supabase
                .getAll {
                    filter {
                        if (phoneNumbers.isNotEmpty()) {
                            or {
                                phoneNumbers.forEach { phoneNumber ->
                                    eq("telephone", phoneNumber)
                                }
                            }
                        }
                    }
                }
                .map { it.toModel() }
        }

    override suspend fun getTravelAgentsByLogo(logoId: String): Result<List<TravelAgentModel>> =
        runCatching {
            supabase.getAll {
                filter {
                    eq("logo_file_id", logoId)
                }
            }.map { it.toModel() }
        }
}
