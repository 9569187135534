package client.pages.components.service

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import client.components.widgets.EsSmallIcon
import client.ui.EsIcons
import client.ui.iconSrc
import com.essntl.features.proposal.domain.model.fullproposal.fullservice.FullCategoryModel
import com.varabyte.kobweb.compose.css.BackgroundPosition
import com.varabyte.kobweb.compose.css.BackgroundSize
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.backgroundImage
import com.varabyte.kobweb.compose.ui.modifiers.backgroundPosition
import com.varabyte.kobweb.compose.ui.modifiers.backgroundSize
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.thenIf

@Composable
fun DefaultServiceImage(
    serviceImage: String?,
    category: FullCategoryModel?,
    onImageClicked: () -> Unit,
    modifier: Modifier = Modifier,
) {
    val imageUrl = remember(serviceImage, category) {
        serviceImage ?: category?.iconUrl
    }

    var isHovered by remember { mutableStateOf(false) }

    Box(
        modifier = modifier
            .then(
                if (imageUrl == null)
                    Modifier
                        .backgroundColor(Colors.LightGrey)
                else
                    Modifier
                        .backgroundImage(url(imageUrl))
                        .backgroundSize(BackgroundSize.Cover)
                        .backgroundPosition(BackgroundPosition.of(CSSPosition.Center)),
            )
            .thenIf(
                condition = serviceImage != null,
                other = Modifier
                    .onMouseEnter {
                        isHovered = true
                    }
                    .onMouseLeave {
                        isHovered = false
                    }
                    .onClick {
                        if (imageUrl != null) {
                            onImageClicked()
                        }
                    },
            )
            .cursor(Cursor.Pointer),
    ) {
        if (isHovered && serviceImage != null)
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier
                    .fillMaxSize()
                    .backgroundColor(Colors.Black.copy(alpha = 130)),
            ) {
                EsSmallIcon(
                    iconRes = iconSrc(EsIcons.Maximize),
                )
            }
    }
}