package client.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import client.pages.ProposalState
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import core.config.buildFlavor
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.Text

@Composable
fun ProposalScreen(
    state: ProposalState,
) {

    val fullProposal = remember(state.getFullProposal.data) { state.getFullProposal.data }

    Box(
        contentAlignment = Alignment.TopStart,
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(Color.rgb(0xF2EFEA)),
    ) {
        if (fullProposal == null)
            Text(
                value = "Loading...",
            )
        else
            ProposalScreenContent(
                fullProposal = fullProposal,
                allProposalService = state.proposalServicesToUiStateList(fullProposal),
                onDownloadFile = {},
            )
    }
}