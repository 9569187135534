package core.dateformat.domain

import com.essntl.core.utils.datetime.formatDateToDDMMYYYY
import com.essntl.core.utils.datetime.formatDateToMMDDYYYY
import core.datastore.data.DatastoreManager
import org.koin.core.annotation.Single

@Single
class DateFormatUseCase(
    private val datastoreManager: DatastoreManager,
) {
    fun getDateFormat() =
        datastoreManager.getData(DatastoreManager.DATE_FORMAT) ?: DateFormat.MONTH_DAY.format

    fun changeDateFormat(dateFormat: String) =
        datastoreManager.saveData(DatastoreManager.DATE_FORMAT, dateFormat)

    fun formatDate(
        date: String?,
        separator: String = "/",
    ): String =
        when (getDateFormat()) {
            DateFormat.MONTH_DAY.format -> formatDateToMMDDYYYY(date, separator)
            DateFormat.DAY_MONTH.format -> formatDateToDDMMYYYY(date, separator)
            else -> ""
        }
}